import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import AirNGBar from './AirNGBar';

// TODO remove index.js
const styles = (theme) => ({
  userButton: {
    marginRight: theme.spacing(2),
    textTransform: 'none',
  },
  username: {
    fontSize: `${14}px !important`,
    marginRight: theme.spacing(1) / 2,
  },
  iconButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  appname: {

  },
  logo: {
    width: 100,
    height: 30,
  },
});

const mapStateToProps = (state) => ({
  username: state.fas.keycloak.parsedToken.name ? state.fas.keycloak.parsedToken.name : state.fas.keycloak.parsedToken.preferred_username,
  loading: state.fas.keycloak.loading || state.category.loading || state.configuration.loading,
  theme: state.configuration.theme,
  from: state.time.from,
  to: state.time.to,
  title: state.time.title,
  selectedApplication: state.category.currentApp,
});

export default connect(mapStateToProps)(withStyles(styles)(AirNGBar));
