import React, { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { io } from 'socket.io-client';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import notificationSound from './assets/notification.wav';
import NotificationMessage from './NotificationMessage';
import CloseButton from './CloseButton';

const sockets = [];

const notificationSoundPlayer = new Audio(notificationSound);

function NotificationPopup({ notificationServers, sound }) {
  const playNotificationSound = useCallback(() => {
    if (sound) notificationSoundPlayer.play();
  }, [sound]);

  const addNotification = useCallback(({ message, messageID, socket }) => {
    toast('Close all', {
      onClose: toast.dismiss,
      position: toast.POSITION.BOTTOM_RIGHT,
      autoClose: false,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
      closeButton: false,
      toastId: 'ack_all',
    });
    toast(<NotificationMessage message={message} />, {
      position: toast.POSITION.BOTTOM_RIGHT,
      autoClose: false,
      hideProgressBar: false,
      closeOnClick: false,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
      toastId: messageID,
      closeButton: CloseButton,
      // Sound works only if user interacted with the application at least one time
      onOpen: playNotificationSound,
      onClose: () => socket.emit('ack', { messageID }),
    });
  }, [playNotificationSound]);

  const setUpSocket = useCallback((server) => {
    const serverURL = new URL(server);

    const socket = io(serverURL.origin, {
      path: `${serverURL.pathname}/socket.io`.replace(/\/\//g, '/'),
    });

    socket.on('connect', () => {
      console.log('connected: ', socket.connected);
    });

    socket.on('notification', (data) => addNotification({ ...data, socket }));

    socket.on('ack', ({ messageId }) => toast.dismiss(messageId));

    return socket;
  }, [addNotification]);

  useEffect(() => {
    notificationServers.forEach((server) => sockets.push(setUpSocket(server)));
    return () => sockets.forEach((socket) => socket.disconnect());
  }, [notificationServers, setUpSocket]);

  return <div />;
}

NotificationPopup.propTypes = {
  notificationServers: PropTypes.arrayOf(PropTypes.string),
  sound: PropTypes.bool.isRequired,
};

NotificationPopup.defaultProps = {
  notificationServers: [],
};

const mapStateToProps = (state) => ({
  sound: state.notification.sound,
});

export default connect(mapStateToProps)(NotificationPopup);
