import React, { useState } from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Iframe from "../Iframe";

function ApplicationsMenu(props) {
  const { tabs } = props;

  const [selectedTab, setTab] = useState();
  const handleChange = (_, newValue) => setTab(newValue);

  if (tabs.length === 0 || !tabs[0].label) {
    return <p>Application is not well configured.</p>
  }

  if (!selectedTab) {
    setTab(tabs[0].url);
  }

  return (
    <div
      style={{
        height: '93%',
      }}
    >
      <Tabs
        value={selectedTab}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
        centered
        variant="fullWidth"
      >
        { tabs.map((tab) => {
            return (<Tab key={tab.label} label={tab.label} value={tab.url}/>);
          })
        }
      </Tabs>
      <Iframe name="Application is displayed in tab." url={selectedTab} />
    </div>
  );
}

export default ApplicationsMenu;
