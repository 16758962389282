import React, { useEffect, useState } from 'react';
import ServerIcon from '@material-ui/icons/Dns';
import { makeStyles } from '@material-ui/styles';

const timezone = window._env_.REACT_APP_SERVER_TIMEZONE;
const serverId = window._env_.REACT_APP_SERVER_ID;

let intervalId = null;

const TEN_SECONDS = 10000;

const useStyles = makeStyles({
  container: {
    display: 'inline-block',
    '&>*': {
      verticalAlign: 'middle',
    },
  },
});

function ServerInformations() {
  const classes = useStyles();
  const [date, setDate] = useState('');

  const refreshDate = () => {
    if (timezone) {
      const newDate = new Date().toLocaleString(undefined, {
        timeZone: timezone,
        hourCycle: 'h23',
        hour: '2-digit',
        minute: '2-digit',
      });
      setDate(newDate);
    }
  };

  useEffect(() => {
    refreshDate();
    intervalId = setInterval(refreshDate, TEN_SECONDS);

    return () => clearInterval(intervalId);
  }, []);

  const ariaLabel = `Server ID ${serverId}`;

  return (
    <div className={classes.container}>
      {serverId && (
        <>
          <ServerIcon titleAccess={ariaLabel} aria-label={ariaLabel} />
          <span>{serverId}</span>
        </>
      )}
      {serverId && timezone && <span> - </span>}
      {timezone && <span>{date}</span>}
    </div>
  );
}

export default ServerInformations;
