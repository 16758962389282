export const CHANGE_TIME = '@time/CHANGE_TIME';
export const CHANGE_AUTOREFRESH = '@time/CHANGE_AUTOREFRESH';

export const changeTime = (title, from, to = { now: true }) => async (dispatch) => {
  dispatch({
    type: CHANGE_TIME,
    payload: {
      title,
      from,
      to,
    },
  });
};

export const changeAutorefresh = (refreshStatus, refreshInterval) => async (dispatch) => {
  dispatch({
    type: CHANGE_AUTOREFRESH,
    payload: {
      refreshStatus,
      refreshInterval,
    },
  });
};
