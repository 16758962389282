import { TOGGLE_NOTIFICATION_SOUND } from "../actions/notification.actions";

const initialState = {
  sound: true,
};

function notificationReducer(state = initialState, action) {
  switch (action.type) {
    case TOGGLE_NOTIFICATION_SOUND:
      return { ...state, sound: action.payload };
    default:
      return state;
  }
}

export default notificationReducer;
