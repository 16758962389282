const messages = {
  languages: 'Langages',
  logout: 'Déconnexion',
  now: 'maintenant',

  picker_absolute: 'absolu',
  picker_autorefresh: 'actualisation',
  picker_ago: 'auparavant',
  picker_from: 'Du :',
  picker_ok: 'go',
  picker_on: 'on',
  picker_off: 'off',
  picker_quick: 'prédéfini',
  picker_relative: 'relatif',
  picker_title_from: 'de',
  picker_title_last: 'il y a moins de',
  picker_title_to: 'à',
  picker_title_today: "aujourd'hui",
  picker_to: 'Au :',

  picker_quick_today: "Aujourd'hui",
  picker_quick_thisweek: 'Cette semaine',
  picker_quick_thismonth: 'Ce mois',
  picker_quick_thisyear: 'Cette année',
  picker_quick_thedaysofar: 'Jour écoulé',
  picker_quick_weektodate: 'Semaine écoulée',
  picker_quick_monthtodate: 'Mois écoulé',
  picker_quick_yeartodate: 'Année écoulée',
  picker_quick_yesterday: 'Hier',
  picker_quick_daybeforeyesterday: 'Avant-hier',
  picker_quick_thisdaylastweek: 'Ce jour la semaine dernière',
  picker_quick_previousweek: 'La semaine dernière',
  picker_quick_previousmonth: 'Le mois dernier',
  picker_quick_previousyear: "L'année dernière",
  picker_quick_last15minutes: '15 dernières minutes',
  picker_quick_last30minutes: '30 dernières minutes',
  picker_quick_last1hour: 'Dernière heure',
  picker_quick_last4hours: '4 dernières heures',
  picker_quick_last12hours: '12 dernières heures',
  picker_quick_last24hours: '24 dernières heures',
  picker_quick_last7days: '7 derniers jours',
  picker_quick_last30days: '30 derniers jours',
  picker_quick_last60days: '60 derniers jours',
  picker_quick_last90days: '90 derniers jours',
  picker_quick_last6months: '6 derniers mois',
  picker_quick_last1year: 'Dernière année',
  picker_quick_last2years: '2 dernières années',
  picker_quick_last5years: '5 dernières années',

  picker_unit_second: 'seconde',
  picker_unit_second_plural: 'secondes',
  picker_unit_minute: 'minute',
  picker_unit_minute_plural: 'minutes',
  picker_unit_hour: 'heure',
  picker_unit_hour_plural: 'heures',
  picker_unit_day: 'jour',
  picker_unit_day_plural: 'jours',
  picker_unit_week: 'semaine',
  picker_unit_week_plural: 'semaines',
  picker_unit_month: 'mois',
  picker_unit_month_plural: 'mois',
  picker_unit_year: 'an',
  picker_unit_year_plural: 'ans',

  themes: 'Thèmes',
};
// TODO: proofread

export default messages;
