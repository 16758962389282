import React from 'react';
import PropTypes from 'prop-types';
import Iframe from './Iframe';
import {
  MULTIPLE_TYPE, WORK_IN_PROGRESS_TYPE, WELCOME_TYPE, PICKER_REGULAR_APPLICATIONS, PICKER_KIBANA_APPLICATIONS,
} from '../../services/constants';

import ApplicationsMenu from './ApplicationsMenu';
import WorkInProgress from './WorkInProgress';
import Welcome from './Welcome';

function generateUrl(selectedApplication, from, to, refreshStatus, refreshInterval) {
  if (!selectedApplication?.url) return null;
  // TODO: when application type is available, check application type instead of URL contents
  if (!PICKER_KIBANA_APPLICATIONS.every((v) => !selectedApplication.url.includes(v))) {
    const regexTimeKibana = /time:\([^)]*/;
    const formatTimeKibana = 'YYYY-MM-DDTHH:mm:ss';
    const generatedTimeFilterKibana = `time:(from:'${from.format(formatTimeKibana)}',to:'${to.now ? 'now' : to.format(formatTimeKibana)}'`;
    const regexRefreshKibana = /refreshInterval:\([^)]*/;
    const refreshStatusKibana = refreshStatus ? 'f' : 't';
    const generatedRefreshKibana = `refreshInterval:(pause:!${refreshStatusKibana},value:${refreshInterval}`;
    return selectedApplication.url.replace(regexTimeKibana, generatedTimeFilterKibana).replace(regexRefreshKibana, generatedRefreshKibana);
  }
  if (!PICKER_REGULAR_APPLICATIONS.every((v) => !selectedApplication.url.includes(v))) {
    const url = new URL(selectedApplication.url);
    url.searchParams.set('from', from.unix());
    url.searchParams.set('to', to.now ? 'now' : to.unix());
    return url.href;
  }
  return selectedApplication.url;
}

function AppFrame(props) {
  const {
    selectedApplication, from, to, refreshStatus, refreshInterval,
  } = props;
  const generatedUrl = generateUrl(selectedApplication, from, to, refreshStatus, refreshInterval);
  if (selectedApplication.type === WORK_IN_PROGRESS_TYPE) {
    return <WorkInProgress />;
  }
  if (selectedApplication.type === WELCOME_TYPE) {
    return <Welcome />;
  }
  if (selectedApplication.type === MULTIPLE_TYPE) {
    return <ApplicationsMenu tabs={selectedApplication.tabs} />;
  }
  return <Iframe name={selectedApplication.name} url={generatedUrl} />;
}

AppFrame.propTypes = {
  selectedApplication: PropTypes.object.isRequired,
  from: PropTypes.object.isRequired,
  to: PropTypes.object.isRequired,
  refreshStatus: PropTypes.bool.isRequired,
  refreshInterval: PropTypes.number.isRequired,
};

export default AppFrame;
