const messages = {
  languages: 'Languages',
  logout: 'Logout',
  now: 'now',

  picker_absolute: 'absolute',
  picker_autorefresh: 'auto refresh',
  picker_ago: 'ago',
  picker_from: 'From:',
  picker_ok: 'go',
  picker_on: 'on',
  picker_off: 'off',
  picker_quick: 'quick',
  picker_relative: 'relative',
  picker_title_from: 'from',
  picker_title_last: 'last',
  picker_title_to: 'to',
  picker_title_today: 'today',
  picker_to: 'To:',

  picker_quick_today: 'Today',
  picker_quick_thisweek: 'This week',
  picker_quick_thismonth: 'This month',
  picker_quick_thisyear: 'This year',
  picker_quick_thedaysofar: 'The day so far',
  picker_quick_weektodate: 'Week to date',
  picker_quick_monthtodate: 'Month to date',
  picker_quick_yeartodate: 'Year to date',
  picker_quick_yesterday: 'Yesterday',
  picker_quick_daybeforeyesterday: 'Day before yesterday',
  picker_quick_thisdaylastweek: 'This day last week',
  picker_quick_previousweek: 'Previous week',
  picker_quick_previousmonth: 'Previous month',
  picker_quick_previousyear: 'Previous year',
  picker_quick_last15minutes: 'Last 15 minutes',
  picker_quick_last30minutes: 'Last 30 minutes',
  picker_quick_last1hour: 'Last 1 hour',
  picker_quick_last4hours: 'Last 4 hours',
  picker_quick_last12hours: 'Last 12 hours',
  picker_quick_last24hours: 'Last 24 hours',
  picker_quick_last7days: 'Last 7 days',
  picker_quick_last30days: 'Last 30 days',
  picker_quick_last60days: 'Last 60 days',
  picker_quick_last90days: 'Last 90 days',
  picker_quick_last6months: 'Last 6 months',
  picker_quick_last1year: 'Last 1 year',
  picker_quick_last2years: 'Last 2 years',
  picker_quick_last5years: 'Last 5 years',

  picker_unit_second: 'second',
  picker_unit_second_plural: 'seconds',
  picker_unit_minute: 'minute',
  picker_unit_minute_plural: 'minutes',
  picker_unit_hour: 'hour',
  picker_unit_hour_plural: 'hours',
  picker_unit_day: 'day',
  picker_unit_day_plural: 'days',
  picker_unit_week: 'week',
  picker_unit_week_plural: 'weeks',
  picker_unit_month: 'month',
  picker_unit_month_plural: 'months',
  picker_unit_year: 'year',
  picker_unit_year_plural: 'years',

  themes: 'Themes',
};
// TODO: proofread

export default messages;
