import { Link } from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';

import { FormattedMessage } from 'react-intl';

function QuickLink(props) {
  const { timerange, onClick } = props;
  return (
    <>
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <Link href="#" color="inherit" onClick={onClick}>
        <FormattedMessage id={`picker_quick_${timerange}`} />
      </Link>
      {['yeartodate', 'previousyear', 'last7days', 'last5years'].includes(timerange) ? '' : <><br /><br /></>}
    </>
  );
}

QuickLink.propTypes = {
  timerange: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default QuickLink;
