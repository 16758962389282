import React from 'react';
import { connect } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import { ThemeProvider } from '@material-ui/styles';
import { IntlProvider } from 'react-intl';
import 'react-toastify/dist/ReactToastify.min.css';

import { getTheme } from 'fas-theme-helper';
import * as configurationActions from 'fas-react-configuration-helper/actions/configurationActions';

import * as categoryActions from './redux/actions/category.actions';

import AirNGBar from './containers/AirNGBar';
import AppFrame from './components/AppFrame';
import './App.css';

import languages from './languages';
import { APPLICATION_NAME } from './services/constants';
import NotificationPopup from './components/NotificationPopup';

class App extends React.Component {
  componentDidMount() {
    const {
      init,
      getCategories,
    } = this.props;
    init();
    // TODO change this to avoid 401 if init takes too long.
    setInterval(() => {
      getCategories();
    }, 5000);
  }

  render() {
    const { theme, language } = this.props;
    return (
      <div className="App">
        <IntlProvider
          locale={language}
          messages={
            languages[language]
              ? languages[language].messages
              : languages.en.messages
          }
        >
          <ThemeProvider theme={getTheme(theme)}>
            <ToastContainer
              newestOnTop
              style={{
                bottom: '0em',
                overflowY: 'auto',
                overflowX: 'hidden',
                maxHeight: '100vh',
              }}
            />
            <AirNGBar appName="SMARTINFRA - PORTAL" />
            <AppFrame />
            <NotificationPopup />
          </ThemeProvider>
        </IntlProvider>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  theme: state.configuration.theme,
  language: state.configuration.language,
});

const mapDispatchToProps = (dispatch) => ({
  init: () => dispatch(configurationActions.initAndAuth(window._env_.REACT_APP_CONFIG_MODE, APPLICATION_NAME, [categoryActions.getCategories])),
  getCategories: () => dispatch(categoryActions.getCategories()),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
