import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import { toggleNotificationSound } from '../../../redux/actions/notification.actions';

const useStyles = makeStyles({
  notificationBtn: {
    color: 'red',
    fontWeight: 'bold',
  },
});

function NotificationMessage({
  message, closeToast,
}) {
  const classes = useStyles();

  return (
    <div>
      <Button onClick={closeToast} className={classes.notificationBtn}>
        <span role="img" aria-label="Panda">
          ❗
        </span>
        &nbsp;
        {message}
      </Button>
    </div>
  );
}

NotificationMessage.propTypes = {
  message: PropTypes.string.isRequired,
  closeToast: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  sound: state.notification.sound,
});

const mapDispatchToProps = (dispatch) => ({
  onSoundBtnClick: () => dispatch(toggleNotificationSound),
});

export default connect(mapStateToProps, mapDispatchToProps)(NotificationMessage);
