import React, { Component } from 'react';
//TODO make individual imports.
import {
  Popper, Grow, Paper, ClickAwayListener, MenuList, ListItemText, Collapse, List, ListItem,
} from '@material-ui/core';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import MenuItem from '@material-ui/core/MenuItem';
import PaletteIcon from '@material-ui/icons/Palette';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import ExpandMore from '@material-ui/icons/ExpandMore';
import CheckIcon from '@material-ui/icons/Check';
import TranslateIcon from '@material-ui/icons/Translate';

import { FormattedMessage } from 'react-intl';

import themes from 'fas-theme-helper';
import languages from '../../languages';

class UserMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isThemesOpen: false,
      isLanguagesOpen: false,
    };
  }

  openCloseTheme = () => {
    const { isThemesOpen } = this.state;
    this.setState({ isThemesOpen: !isThemesOpen });
  }

  openCloseLanguages = () => {
    const { isLanguagesOpen } = this.state;
    this.setState({ isLanguagesOpen: !isLanguagesOpen });
  }

  render() {
    const {
      anchor, handleClose, logout, classes, theme, language, changeTheme, changeLanguage,
    } = this.props;
    const { isThemesOpen, isLanguagesOpen } = this.state;
    return (
      <Popper
        open={Boolean(anchor)}
        anchorEl={anchor}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            //TODO refactor extract style
            style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList>
                  { //TODO extract into dedicated component
                  }
                 <MenuItem onClick={this.openCloseTheme}>
                      <ListItemIcon tyle={{ minWidth: '4vh' }}>
                      <PaletteIcon />
                    </ListItemIcon>
                    <ListItemText>
                      <FormattedMessage
                        id="themes"
                      />
                    </ListItemText>
                    { isThemesOpen ? <ExpandMore style={{ minWidth: '4vh' }} /> : <NavigateBeforeIcon style={{ minWidth: '4vh' }} /> }
                  </MenuItem>
                  <Collapse in={isThemesOpen} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                      { Object.keys(themes).map(themeID => (
                        <ListItem key={themeID} button className={classes.nested} onClick={() => changeTheme(themeID)}>
                          <ListItemText primary={themeID} />
                          { theme.toLowerCase() === themeID.toLowerCase() && <CheckIcon /> }
                        </ListItem>
                      ))}
                    </List>
                  </Collapse>
                  <MenuItem onClick={this.openCloseLanguages}>
                    <ListItemIcon style={{ minWidth: '4vh' }}>
                      <TranslateIcon />
                    </ListItemIcon>
                    <ListItemText>
                      <FormattedMessage
                        id="languages"
                      />
                    </ListItemText>
                    { isLanguagesOpen ? <ExpandMore style={{ minWidth: '4vh' }} /> : <NavigateBeforeIcon style={{ minWidth: '4vh' }} /> }
                  </MenuItem>
                  <Collapse in={isLanguagesOpen} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                      { Object.keys(languages).map(languageID => (
                        <ListItem key={languageID} button className={classes.nested} onClick={() => changeLanguage(languageID)}>
                          <ListItemText primary={languages[languageID].label} />
                          { languageID.toLowerCase() === language.toLowerCase() && <CheckIcon />}
                        </ListItem>
                      ))}
                    </List>
                  </Collapse>
                  <MenuItem onClick={logout}>
                    <ListItemIcon style={{ minWidth: '4vh' }}>
                      <PowerSettingsNewIcon />
                    </ListItemIcon>
                    <ListItemText>
                      <FormattedMessage
                        id="logout"
                      />
                    </ListItemText>
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    );
  }
}

export default UserMenu;
