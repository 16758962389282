import {
  get,
} from 'fas-http-helper';

export const SET_CURRENT_APP = '@categories/SET_CURRENT_APP';
export const SET_TOKEN = '@categories/SET_TOKEN';

export const GET_CATEGORIES = '@categories/GET_CATEGORIES';
export const GET_CATEGORIES_SUCCESS = '@categories/GET_CATEGORIES_SUCCESS';
export const GET_CATEGORIES_ERROR = '@categories/GET_CATEGORIES_ERROR';

export const getCategories = () => async (dispatch, getState) => {
  dispatch({ type: GET_CATEGORIES });
  const token = getState().fas.keycloak.accessToken;
  get('category', undefined, false, {
    Authorization: `Bearer ${token}`,
  }).then((response) => {
    const { parsedToken } = getState().fas.keycloak;
    const categories = response.data;
    for (const category of categories) {
      category.applications = category.applications.filter((application) => {
        for (const role of parsedToken.roles) {
          if (application.permissions.includes(role)) {
            return true;
          }
        }
        return false;
      });
    }
    const filtered = categories.filter((category) => category.applications.length !== 0);
    dispatch({ type: GET_CATEGORIES_SUCCESS, payload: filtered });
  }).catch((error) => {
    dispatch({ type: GET_CATEGORIES_ERROR, payload: error });
  });
};

export const setCurrentApp = (application) => ({
  type: SET_CURRENT_APP,
  payload: {
    application,
  },
});
