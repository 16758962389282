import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';

import * as keycloakActions from 'fas-react-configuration-helper/actions/keycloakActions';

import { setCurrentApp } from '../../redux/actions/category.actions';
import { changeTheme, changeLanguage } from '../../redux/actions/configuration.actions';
import UserMenu from './UserMenu';

const styles = theme => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  shapeCircle: {
    borderRadius: '50%',
  },
});

const mapDispatchToProps = dispatch => ({
  setCurrentApp: application => dispatch(setCurrentApp(application)),
  logout: () => dispatch(keycloakActions.logout()),
  changeTheme: id => dispatch(changeTheme(id)),
  changeLanguage: id => dispatch(changeLanguage(id)),
});

const mapStateToProps = state => ({
  theme: state.configuration.theme,
  language: state.configuration.language,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(UserMenu));
