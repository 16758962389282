export const APPLICATION_NAME = 'portal';

export const MULTIPLE_TYPE = 'multiple';
export const WORK_IN_PROGRESS_TYPE = 'workinprogress';
export const WELCOME_TYPE = 'welcome';

export const ABSOLUTE = 6;
export const QUICK = 7;
export const RELATIVE = 8;
export const AUTOREFRESH = 9;

export const PICKER_REGULAR_APPLICATIONS = ['fascan_ui', 'gencrud', 'windfarm_alarm_logger_ui'];
export const PICKER_KIBANA_APPLICATIONS = ['kibana'];
