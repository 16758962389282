import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Button, Popper, Grow, Paper, MenuList, Grid, Box,
} from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { AbsolutePanel, QuickPanel, RelativePanel } from '.';
import {
  ABSOLUTE, AUTOREFRESH, QUICK, RELATIVE,
} from '../../services/constants';
import AutorefreshPanel from './AutorefreshPanel';

class PickerMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activePanel: QUICK,
    };
  }

  activatePanel = (panel) => {
    this.setState(() => ({ activePanel: panel }));
  };

  render() {
    const {
      anchor,
      airngbarCallback,
      dateFrom,
      dateTo,
    } = this.props;
    const { activePanel } = this.state;
    return (
      <Popper
        open={Boolean(anchor)}
        anchorEl={anchor}
        transition
        disablePortal
      >
        {({ TransitionProps }) => (
          <Grow
            {...TransitionProps}
          >
            <Paper>

              <MenuList>
                <Box
                  marginLeft={2}
                  textAlign="left"
                  fontSize={10}
                  fontFamily="monospace"
                  color="gray"
                >
                  {dateFrom.format('YYYY-MM-DD HH:mm')} - {dateTo.now ? <FormattedMessage id="now" /> : dateTo.format('YYYY-MM-DD HH:mm')}
                </Box>
                <Grid container spacing={0}>
                  <Grid item>
                    <Box padding={2}>
                      {QUICK === activePanel && (
                        <QuickPanel
                          airngbarCallback={airngbarCallback}
                        />
                      )}
                      {RELATIVE === activePanel && (
                        <RelativePanel
                          airngbarCallback={airngbarCallback}
                        />
                      )}
                      {ABSOLUTE === activePanel && (
                        <AbsolutePanel
                          dateFrom={dateFrom}
                          dateTo={dateTo}
                          airngbarCallback={airngbarCallback}
                        />
                      )}
                      {AUTOREFRESH === activePanel && (
                        <AutorefreshPanel
                          airngbarCallback={airngbarCallback}
                        />
                      )}
                    </Box>
                  </Grid>
                  <Grid item>
                    <Box margin={1}>
                      <Button
                        onClick={() => this.activatePanel(QUICK)}
                        variant="contained"
                        color={QUICK === activePanel ? 'primary' : 'default'}
                        fullWidth
                      >
                        <FormattedMessage id="picker_quick" />
                      </Button>
                    </Box>
                    <Box margin={1}>
                      <Button
                        onClick={() => this.activatePanel(RELATIVE)}
                        variant="contained"
                        color={RELATIVE === activePanel ? 'primary' : 'default'}
                        fullWidth
                      >
                        <FormattedMessage id="picker_relative" />
                      </Button>
                    </Box>
                    <Box margin={1}>
                      <Button
                        onClick={() => this.activatePanel(ABSOLUTE)}
                        variant="contained"
                        color={ABSOLUTE === activePanel ? 'primary' : 'default'}
                        fullWidth
                      >
                        <FormattedMessage id="picker_absolute" />
                      </Button>
                    </Box>
                    <Box margin={1}>
                      <p>&nbsp;</p>
                    </Box>
                    <Box margin={1}>
                      <Button
                        onClick={() => this.activatePanel(AUTOREFRESH)}
                        variant="contained"
                        color={AUTOREFRESH === activePanel ? 'primary' : 'default'}
                        fullWidth
                      >
                        <FormattedMessage id="picker_autorefresh" />
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </MenuList>
            </Paper>
          </Grow>
        )}
      </Popper>
    );
  }
}

PickerMenu.propTypes = {
  anchor: PropTypes.object,
  airngbarCallback: PropTypes.func.isRequired,
  dateFrom: PropTypes.object.isRequired,
  dateTo: PropTypes.object.isRequired,
};

export default PickerMenu;
