import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { setCurrentApp } from '../../../redux/actions/category.actions';
import ItemMenu from './ItemMenu';

const styles = theme => ({
  root: {
    width: '100%',
    maxWidth: 360,
    marginTop: -5,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  category: {
    backgroundColor: 'rgba(0, 0, 0, 0.06)',
  },
});

const mapDispatchToProps = dispatch => ({
  setCurrentApp: application => dispatch(setCurrentApp(application)),
});

export default connect(
  null,
  mapDispatchToProps,
)(withStyles(styles)(ItemMenu));
