import Iframe from "./Iframe";
import {withStyles} from "@material-ui/core/styles";

const styles = theme => ({
  appFrame: {
    flexGrow: 2,
    border: 0,
    display: 'block',
    width: '100%',
    height: '100%'
  },
});

export default withStyles(styles)(Iframe);
