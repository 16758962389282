import React, { Component } from 'react';
import PropTypes from 'prop-types';

// TODO change this with individual exports.
import {
  AppBar,
  Toolbar,
  LinearProgress,
  Typography,
  IconButton,
  Button,
} from '@material-ui/core';

import UserIcon from '@material-ui/icons/AccountCircle';
import MenuIcon from '@material-ui/icons/Menu';
import HistoryIcon from '@material-ui/icons/History';

import { injectIntl } from 'react-intl';
import AppMenu from '../../components/Menu';
import UserMenu from '../../components/UserMenu';
import logo from './assets/logo-engie-fabricom-white.svg';
import {
  ABSOLUTE, PICKER_KIBANA_APPLICATIONS, PICKER_REGULAR_APPLICATIONS, QUICK, RELATIVE,
} from '../../services/constants';
import { PickerMenu } from '../../components/Picker';
import ServerInformations from '../../components/ServerInformations/ServerInformations';

class AirNGBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMenuOpen: false,
      userMenuAnchor: null,
      pickerMenuAnchor: null,
    };
  }

  toggleMenu = () => {
    this.setState((prevState) => ({ ...prevState, isMenuOpen: !prevState.isMenuOpen }));
  };

  displayUserMenu = (event) => {
    const target = event.currentTarget;
    this.setState({ userMenuAnchor: target }, this.hidePickerMenu);
  };

  hideUserMenu = () => {
    this.setState({ userMenuAnchor: null });
  }

  shouldDisplayPicker = (selectedApplication) => {
    if (!selectedApplication?.url) return false;
    return !PICKER_REGULAR_APPLICATIONS.concat(PICKER_KIBANA_APPLICATIONS).every((v) => !selectedApplication.url.includes(v));
  }

  togglePickerMenu = (event) => {
    const target = event.currentTarget;
    this.setState((prevState) => (
      { ...prevState, pickerMenuAnchor: prevState.pickerMenuAnchor ? null : target }
    ));
  };

  hidePickerMenu = () => {
    this.setState({ pickerMenuAnchor: null });
  }

  translatePickerMenuTitle = () => {
    const {
      intl, title, from, to,
    } = this.props;
    switch (title.type) {
      case ABSOLUTE:
        return `
          ${intl.formatMessage({ id: 'picker_title_from' })}
          ${from.format('YYYY-MM-DD HH:mm')}
          ${intl.formatMessage({ id: 'picker_title_to' })}
          ${to.now ? intl.formatMessage({ id: 'now' }) : to.format('YYYY-MM-DD HH:mm')}
        `;
      case QUICK:
        return intl.formatMessage({ id: title.id });
      case RELATIVE:
        return `
          ${intl.formatMessage({ id: 'picker_title_last' })}
          ${title.amount}
          ${intl.formatMessage({ id: `picker_unit_${title.unit}${(title.amount > 1 ? '_plural' : '')}` })}
        `;
      default:
        return title;
    }
  }

  render() {
    const {
      classes, username, loading, selectedApplication, from, to,
    } = this.props;
    const { isMenuOpen, userMenuAnchor, pickerMenuAnchor } = this.state;
    return (
      <div className={classes.root}>
        <AppBar position="static">
          <Toolbar variant="dense">
            <IconButton
              edge="start"
              className={classes.iconButton}
              color="inherit"
              aria-label="menu"
              onClick={this.toggleMenu}
            >
              <MenuIcon />
            </IconButton>
            <img
              src={logo}
              alt="Engie logo"
              className={classes.logo}
            />
            <Typography className={classes.appname}>
              { selectedApplication && selectedApplication.name ? selectedApplication.name : '' }
            </Typography>
            <Typography variant="body1" component="span" className={classes.title}>
              <ServerInformations />
            </Typography>
            {this.shouldDisplayPicker(selectedApplication) && (
            <Button
              className={classes.userButton}
              edge="start"
              color="inherit"
              onClick={this.togglePickerMenu}
            >
              <HistoryIcon />&nbsp;
              {this.translatePickerMenuTitle().trim().replace(/^\w/, (c) => c.toUpperCase())}
            </Button>
            )}
            <Button
              edge="start"
              color="inherit"
              aria-label="profile"
              onClick={this.displayUserMenu}
            >
              {
                username
                  && (
                  <Typography variant="h6" className={classes.username} id="username">
                    { username }
                  </Typography>
                  )
              }
              <UserIcon />
            </Button>
          </Toolbar>
        </AppBar>
        { loading && <LinearProgress color="secondary" /> }
        <AppMenu isOpen={isMenuOpen} toggleDrawer={this.toggleMenu} />
        <UserMenu anchor={userMenuAnchor} handleClose={this.hideUserMenu} />
        <PickerMenu
          anchor={pickerMenuAnchor}
          dateFrom={from}
          dateTo={to}
          airngbarCallback={this.hidePickerMenu}
        />
      </div>
    );
  }
}

AirNGBar.propTypes = {
  intl: PropTypes.object.isRequired,
  title: PropTypes.object.isRequired,
  from: PropTypes.object.isRequired,
  to: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  username: PropTypes.string,
  loading: PropTypes.bool.isRequired,
  selectedApplication: PropTypes.object.isRequired,
};

export default injectIntl(AirNGBar);
