import { combineReducers } from 'redux';
import fasReducer from 'fas-react-configuration-helper/reducer';

import categoryReducer from './category.reducer';
import configurationReducer from './configuration.reducer';
import timeReducer from "./time.reducer";
import notificationReducer from './notification.reducer';

const rootReducer = combineReducers({
  fas: fasReducer,
  configuration: configurationReducer,
  category: categoryReducer,
  time: timeReducer,
  notification: notificationReducer,
});

export default rootReducer;
