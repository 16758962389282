import { Box, Grid } from '@material-ui/core';
import dayjs from 'dayjs';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { QuickLink } from '.';
import { changeTime } from '../../redux/actions/time.actions';
import { QUICK } from '../../services/constants';

const quickpicks = [
  'today',
  'thisweek',
  'thismonth',
  'thisyear',
  'thedaysofar',
  'weektodate',
  'monthtodate',
  'yeartodate',
  'yesterday',
  'daybeforeyesterday',
  'thisdaylastweek',
  'previousweek',
  'previousmonth',
  'previousyear',
  'last15minutes',
  'last30minutes',
  'last1hour',
  'last4hours',
  'last12hours',
  'last24hours',
  'last7days',
  'last30days',
  'last60days',
  'last90days',
  'last6months',
  'last1year',
  'last2years',
  'last5years',
];

class QuickPanel extends Component {
  createDates = (timerange) => {
    const { airngbarCallback, changeTimeProp } = this.props;
    const pickerMenuTitle = {
      type: QUICK,
      id: `picker_quick_${timerange}`,
    };
    let buffer;
    switch (timerange) {
      case 'today':
        changeTimeProp(pickerMenuTitle, dayjs().startOf('day'), dayjs().endOf('day'));
        break;
      case 'thisweek':
        changeTimeProp(pickerMenuTitle, dayjs().startOf('week'), dayjs().endOf('week'));
        break;
      case 'thismonth':
        changeTimeProp(pickerMenuTitle, dayjs().startOf('month'), dayjs().endOf('month'));
        break;
      case 'thisyear':
        changeTimeProp(pickerMenuTitle, dayjs().startOf('year'), dayjs().endOf('year'));
        break;
      case 'thedaysofar':
        changeTimeProp(pickerMenuTitle, dayjs().startOf('day'));
        break;
      case 'weektodate':
        changeTimeProp(pickerMenuTitle, dayjs().startOf('week'));
        break;
      case 'monthtodate':
        changeTimeProp(pickerMenuTitle, dayjs().startOf('month'));
        break;
      case 'yeartodate':
        changeTimeProp(pickerMenuTitle, dayjs().startOf('year'));
        break;
      case 'yesterday':
        buffer = dayjs().subtract(1, 'day');
        changeTimeProp(pickerMenuTitle, buffer.startOf('day'), buffer.endOf('day'));
        break;
      case 'daybeforeyesterday':
        buffer = dayjs().subtract(2, 'day');
        changeTimeProp(pickerMenuTitle, buffer.startOf('day'), buffer.endOf('day'));
        break;
      case 'thisdaylastweek':
        buffer = dayjs().subtract(1, 'week');
        changeTimeProp(pickerMenuTitle, buffer.startOf('day'), buffer.endOf('day'));
        break;
      case 'previousweek':
        buffer = dayjs().subtract(1, 'week');
        changeTimeProp(pickerMenuTitle, buffer.startOf('week'), buffer.endOf('week'));
        break;
      case 'previousmonth':
        buffer = dayjs().subtract(1, 'month');
        changeTimeProp(pickerMenuTitle, buffer.startOf('month'), buffer.endOf('month'));
        break;
      case 'previousyear':
        buffer = dayjs().subtract(1, 'year');
        changeTimeProp(pickerMenuTitle, buffer.startOf('year'), buffer.endOf('year'));
        break;
      case 'last15minutes':
        changeTimeProp(pickerMenuTitle, dayjs().subtract(15, 'minute'));
        break;
      case 'last30minutes':
        changeTimeProp(pickerMenuTitle, dayjs().subtract(30, 'minute'));
        break;
      case 'last1hour':
        changeTimeProp(pickerMenuTitle, dayjs().subtract(1, 'hour'));
        break;
      case 'last4hours':
        changeTimeProp(pickerMenuTitle, dayjs().subtract(4, 'hour'));
        break;
      case 'last12hours':
        changeTimeProp(pickerMenuTitle, dayjs().subtract(12, 'hour'));
        break;
      case 'last24hours':
        changeTimeProp(pickerMenuTitle, dayjs().subtract(24, 'hour'));
        break;
      case 'last7days':
        changeTimeProp(pickerMenuTitle, dayjs().subtract(7, 'day'));
        break;
      case 'last30days':
        changeTimeProp(pickerMenuTitle, dayjs().subtract(30, 'day'));
        break;
      case 'last60days':
        changeTimeProp(pickerMenuTitle, dayjs().subtract(60, 'day'));
        break;
      case 'last90days':
        changeTimeProp(pickerMenuTitle, dayjs().subtract(90, 'day'));
        break;
      case 'last6months':
        changeTimeProp(pickerMenuTitle, dayjs().subtract(6, 'month'));
        break;
      case 'last1year':
        changeTimeProp(pickerMenuTitle, dayjs().subtract(1, 'year'));
        break;
      case 'last2years':
        changeTimeProp(pickerMenuTitle, dayjs().subtract(2, 'year'));
        break;
      case 'last5years':
      default:
        // fallback: last 5 years
        changeTimeProp(pickerMenuTitle, dayjs().subtract(5, 'year'));
        break;
    }
    airngbarCallback();
  }

  render() {
    return (
      <Box textAlign="left">
        <Grid container spacing={4}>
          <Grid item>
            {quickpicks.slice(0, 8).map((quickpick) => <QuickLink key={quickpick} timerange={quickpick} onClick={() => this.createDates(quickpick)} />)}
          </Grid>
          <Grid item>
            {quickpicks.slice(8, 14).map((quickpick) => <QuickLink key={quickpick} timerange={quickpick} onClick={() => this.createDates(quickpick)} />)}
          </Grid>
          <Grid item>
            {quickpicks.slice(14, 21).map((quickpick) => <QuickLink key={quickpick} timerange={quickpick} onClick={() => this.createDates(quickpick)} />)}
          </Grid>
          <Grid item>
            {quickpicks.slice(21).map((quickpick) => <QuickLink key={quickpick} timerange={quickpick} onClick={() => this.createDates(quickpick)} />)}
          </Grid>
        </Grid>
      </Box>
    );
  }
}

QuickPanel.propTypes = {
  airngbarCallback: PropTypes.func.isRequired,
  changeTimeProp: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  changeTimeProp: (title, from, to) => dispatch(changeTime(title, from, to)),
});

export default connect(
  null,
  mapDispatchToProps,
)(QuickPanel);
