import { Box, Button, Grid } from '@material-ui/core';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { AutorefreshPicker } from '.';
import { changeAutorefresh } from '../../redux/actions/time.actions';

class AutorefreshPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      interval: {
        amount: 5,
        unit: 'second',
      },
    };
  }

  onChangeInterval = (interval) => {
    this.setState(() => ({ interval }));
  }

  computeInterval = () => {
    const { interval } = this.state;
    switch (interval.unit) {
      case 'minute':
        return interval.amount * 1000 * 60;
      case 'hour':
        return interval.amount * 1000 * 60 * 60;
      default:
        return interval.amount * 1000;
    }
  }

  onSubmit = (refreshStatus) => {
    const { airngbarCallback, changeAutorefreshProp } = this.props;
    changeAutorefreshProp(
      refreshStatus,
      this.computeInterval(),
    );
    airngbarCallback();
  }

  render() {
    const { interval } = this.state;
    return (
      <Grid container spacing={2}>
        <Grid item xs={9}>
          <AutorefreshPicker
            interval={interval}
            onChange={this.onChangeInterval}
          />
        </Grid>
        <Grid item xs={3}>
          <Grid container spacing={0}>
            <Grid item xs={3} />
            <Grid item xs={9}>
              <Box>
                <Button
                  variant="contained"
                  onClick={() => this.onSubmit(true)}
                >
                  <FormattedMessage id="picker_on" />
                </Button>
                <p />
                <Button
                  variant="contained"
                  onClick={() => this.onSubmit(false)}
                >
                  <FormattedMessage id="picker_off" />
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

AutorefreshPanel.propTypes = {
  airngbarCallback: PropTypes.func.isRequired,
  changeAutorefreshProp: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  changeAutorefreshProp: (refreshStatus, refreshInterval) => dispatch(changeAutorefresh(refreshStatus, refreshInterval)),
});

export default connect(
  null,
  mapDispatchToProps,
)(AutorefreshPanel);
